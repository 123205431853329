<template>
  <v-row>
    <v-col cols="12" sm="6" lg="4">
      <Block :title="$t('settings.change-password-block-title')">
        <!-- Error display for changing password -->
        <ErrorsDisplay
          :errors="authenticationErrors"
          class="mx-auto"
        />

        <!-- Current password field -->
        <v-text-field
          v-model="currentPassword"
          :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showCurrentPassword ? 'text' : 'password'"
          :label="$t('settings.current-password-label')"
          autocomplete="new-password"
          @click:append="showCurrentPassword = !showCurrentPassword"
          @keyup.enter="changePasswordClicked"
          :error-messages="changePasswordValidationErrors['oldPassword']"
          :error-count="2"
        />
        <!-- New password field -->
        <v-text-field
          v-model="newPassword"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPassword ? 'text' : 'password'"
          :label="$t('settings.new-password-label')"
          autocomplete="new-password"
          @click:append="showNewPassword = !showNewPassword"
          @keyup.enter="changePasswordClicked"
          :error-messages="changePasswordValidationErrors['newPassword']"
          :error-count="5"
        />
        <!-- Repeat new password field -->
        <v-text-field
          v-model="repeatNewPassword"
          :append-icon="showRepeatNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showRepeatNewPassword ? 'text' : 'password'"
          :label="$t('settings.repeat-new-password-label')"
          autocomplete="new-password"
          @click:append="showRepeatNewPassword = !showRepeatNewPassword"
          @keyup.enter="changePasswordClicked"
          :error-messages="changePasswordValidationErrors['repeatNewPassword']"
          :error-count="1"
        />
        <!-- Shows save button or success message -->
        <div class="d-flex justify-end align-center">
          <div v-if="changePasswordSuccess">
            <span class="font-weight-medium me-1">
              {{ $t('settings.change-password-success-text') }}
            </span>
            <v-icon large color="success">
              mdi-check-circle-outline
            </v-icon>
          </div>
          <v-btn
            v-else
            :loading="isBusyAuthenticating"
            color="primary"
            @click="changePasswordClicked"
          >
            {{ $t('settings.save-new-password-button') }}
          </v-btn>
        </div>
      </Block>
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <Block :title="$t('settings.account-block-title')">
        <template #title-actions>
          <!-- Log out button -->
          <v-btn
            :loading="isBusyAuthenticating"
            color="primary"
            @click="logoutClicked"
          >
            {{ $t('settings.logout-button') }}
          </v-btn>
        </template>
        <v-combobox
          v-model="selectedTimezone"
          :items="timezones"
          item-value="name"
          item-text="displayName"
          :return-object="false"
          :label="$t('settings.timezone-label')"
          :hint="$t('settings.timezone-hint')"
          persistent-hint
          class="mt-4"
        />
      </Block>
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <Block :title="$t('settings.style-block-title')">
        <!-- Theme switcher -->
        <v-switch
          v-model="useDarkMode"
          :hint="$t('settings.dark-theme-hint')"
          :label="$t('settings.dark-theme-label')"
          persistent-hint
        />

        <!-- Bottom navigation preference switcher -->
        <v-switch
          v-model="userPrefersBottomNavigation"
          :hint="$t('settings.prefer-bottom-navigation-hint')"
          :label="$t('settings.prefer-bottom-navigation-label')"
          persistent-hint
        />

        <!-- Show advanced chart controls switcher -->
        <v-switch
          v-model="useAdvancedChartControls"
          :label="$t('settings.advanced-chart-controls-label')"
          persistent-hint
        />
      </Block>
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <Block :title="$t('settings.units-block-title')">
        <div class="mt-4">
          <FuelTypeUnitSelector
            v-for="fuelType in fuelTypes"
            :key="fuelType.name"
            :fuelType="fuelType"
          />
        </div>
      </Block>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Block from '@/components/Block'
import ErrorsDisplay from '@/components/ErrorsDisplay'
import FuelTypeUnitSelector from '@/components/inputs/FuelTypeUnitSelector'

export default {
  data () {
    return {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showRepeatNewPassword: false,
      changePasswordSuccess: false,
      changePasswordValidationErrors: {}
    }
  },
  components: { Block, ErrorsDisplay, FuelTypeUnitSelector },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('menuItems', ['updateMenuItemsToRoles']),
    ...mapActions('account', ['logout', 'changeMyPassword']),
    ...mapActions('preferences', ['changeDarkMode', 'changeBottomNavigationPreference', 'changeShowAdvancedChartControls', 'changeTimezone']),
    logoutClicked () {
      this.logout()
        .catch(error => {
          console.error(error)
          console.error('logout failed')
        })
        .finally(() => {
          this.updateMenuItemsToRoles(this.roles)
          this.$router.push({ name: 'Login' })
        })
    },
    changePasswordClicked () {
      this.changePasswordValidationErrors = {}
      this.changeMyPassword({ currentPassword: this.currentPassword, newPassword: this.newPassword, repeatNewPassword: this.repeatNewPassword })
        .then(() => {
          this.currentPassword = ''
          this.newPassword = ''
          this.repeatNewPassword = ''
          this.changePasswordSuccess = true
          this.showSnackbar({
            role: 'success',
            messages: [this.$t('change-password-success-message')],
            duration: 5000
          })
        })
        .catch(error => {
          console.error(error)
          console.error('change password failed')
          if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
            this.changePasswordValidationErrors = error.response.data.errors
          } else {
            this.showSnackbar({
              role: 'error',
              messages: [this.$t('change-password-failed-message')],
              duration: 5000
            })
          }
        })
    }
  },
  computed: {
    ...mapState('fuelTypes', ['fuelTypes']),
    ...mapState('timezones', ['timezones']),
    ...mapGetters('timezones', ['getTimeZoneByName']),
    ...mapState('account', ['isBusyAuthenticating', 'authenticationErrors']),
    ...mapState('preferences', ['prefersBottomNavigation', 'darkMode', 'showAdvancedChartControls', 'timezone']),
    ...mapGetters('account', ['roles']),
    userPrefersBottomNavigation: {
      get: function () {
        return this.prefersBottomNavigation
      },
      set: function (newValue) {
        this.changeBottomNavigationPreference(newValue)
      }
    },
    useAdvancedChartControls: {
      get: function () {
        return this.showAdvancedChartControls
      },
      set: function (newValue) {
        this.changeShowAdvancedChartControls(newValue)
      }
    },
    selectedTimezone: {
      get: function () {
        return this.getTimeZoneByName(this.timezone)
      },
      set: function (newValue) {
        this.changeTimezone(newValue)
      }
    },
    useDarkMode: {
      get: function () {
        return this.darkMode
      },
      set: function (newValue) {
        this.changeDarkMode({ darkMode: newValue, vuetify: this.$vuetify })
      }
    }
  }
}
</script>
