<template>
  <v-select
    v-model="selectedUnit"
    :items="fuelType.units"
    item-value="name"
    :item-text="item => $t(item.longTranslationKey)"
    return-object
    :label="$t(fuelType.translationKey)"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    fuelType: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('fuelTypes', ['getPreferredUnitByFuelType']),
    selectedUnit: {
      get: function () {
        return this.getPreferredUnitByFuelType(this.fuelType)
      },
      set: function (newValue) {
        this.changeUnitPreference({ fuelType: this.fuelType, unit: newValue })
      }
    }
  },
  methods: {
    ...mapActions('fuelTypes', ['changeUnitPreference'])
  }
}
</script>
